<template>
  <h1 style="color: black; opacity: 1;">{{ headerText }}</h1>
  <h3 v-if="editingExisting" style="color: black; opacity: 1;">{{ oldOrganizationVals.organizationName }}</h3>

  <form @submit.prevent="submitForm">
    <div class="row">
      <div class="col-sm">
        <div>
          <label>Name<span>*</span></label>
          <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.organizationName"
                 id="organizationName" placeholder="Organization Name (Required)" @change="validStr($event)" required/>
          <transition name="fade">
            <span v-if="msg.organizationName">Organization Name is {{ msg.organizationName }}</span>
          </transition>
        </div>
        <div class="row">
          <div class="col-sm">
            <label>Contact Name<span>*</span></label>
            <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.contactName"
                   id="contactName" placeholder="Contact Name (Required)" @change="validStr($event)" required/>
          </div>
          <div class="col-sm">
            <label>Contact Email<span>*</span></label>
            <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.contactEmail"
                   id="contactEmail" placeholder="Contact Email (Required)" @change="validateEmail($event)" required/>
            <transition name="fade">
              <span v-if="msg.email">{{ msg.email }}</span>
            </transition>
          </div>
        </div>
        <div class="row" v-if="!showMazInput">

          <div class="col-sm">
            <label>Phone Number<span>*</span></label>
            <input class="form-control" style="height: 41px" v-model="outstateOrganization.phoneNumber"
                   id="phoneNumber" placeholder="Phone Number (Required)" @change="validStr($event)" required/>
            <transition name="fade">
              <span v-if="msg.phoneNumber">Phone Number is {{ msg.phoneNumber }}</span>
            </transition>

          </div>
          <div class="col-sm">
            <label>Fax Number</label>
            <input class="form-control" style="height: 41px" v-model="outstateOrganization.faxNumber"
                   id="faxNumber" placeholder="Fax Number"/>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col">
            <label>Phone Number<span>*</span></label>
            <maz-phone-number-input
                :default-country-code="outstateOrganization.countryCode"
                v-model="outstateOrganization.phoneNumber"
                no-country-selector
                size="sm"
                color="info"
                style="border-style: solid;"
            />
          </div>
          <div class="col">
            <label>Fax Number</label>
            <maz-phone-number-input
                :default-country-code="outstateOrganization.countryCode"
                v-model="outstateOrganization.faxNumber"
                no-country-selector
                size="sm"
                color="info"
                style="border-style: solid; border-radius: 10px;"
            />
          </div>
        </div>
        <div class="row">
          <div>
            <label>Requested By<span>*</span></label>
            <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.requestingUserName" placeholder="Your Name"/>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="row">
          <div class="col-sm">
            <div v-if="isLoaded">
              <CountrySelectComposition
                  @selectedCountry="changeCountry($event)"
                  :defaultState="originalState"
                  :country-disabled="editingExisting"
                  :state-disabled="editingExisting"
                  :default-country="originalCountry"
                  :show-state="false"
                  :show-esu="false"
                  :show-district="false"
                  :show-school="false"
              />
            </div>
          </div>
          <div class="col-sm">
            <div v-if="showStateDisplay">
              <StateSelectComposition
                  @selectedState="changeState($event)"
                  :default-state="originalState"
                  :state-disabled="editingExisting"
                  :show-esu="false"
                  :show-district="false"
                  :show-school="false"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <label>Address<span>*</span></label>
            <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.address" placeholder="Address" required/>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <div>
              <label>City<span>*</span></label>
              <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.city" placeholder="City" required/>
            </div>

          </div>
          <div class="col-sm">
            <div>
              <label>Postal Code<span>*</span></label>
              <input class="form-control" style="height: 41px" type="text" v-model="outstateOrganization.postalCode" placeholder="Postal Code" required/>
            </div>
          </div>
        </div>

      </div>
      <div>
        <button class="button btn-primary" type="submit btn-primary" :disabled="!canSubmit">{{ submitText }}</button>
      </div>
      <!--      <div v-if="canSubmit">-->
      <!--        <button class="button btn-primary" type="submit btn-primary">{{ submitText }}</button>-->
      <!--      </div>-->
    </div>

  </form>
</template>

<script>
import {computed, nextTick, onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";
import CountrySelectComposition from "@/components/LocationSelect/CountrySelectComposition";
import {createPhoneInputMask, numericStringMask} from "@/InputMaskFunctions";
import {EMAIL_REGEX} from "@/ValidConstants";
import {isEqual} from "lodash/lang";
import {useStore} from "vuex";
import Swal from "sweetalert2";
import {useReCaptcha} from "vue-recaptcha-v3";
import {sanitizeNonPasswordInput} from "@/SanitizeInput";
import StateSelectComposition from "@/components/LocationSelect/StateSelectComposition";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

export default {
  name: "OutstateOrganizationPublicForm",
  components: {StateSelectComposition, CountrySelectComposition, MazPhoneNumberInput},
  props: {
    headerText: String,
    submitText: String,
    editingExisting: Boolean,
    countryCode: String,
    stateCode: String,
    orgName: String,
    requestingUserName: String
  },
  setup(props, context) {
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
    const updatingCountry = ref(false);
    const phoneInputModel = ref();
    let outstateOrganization = ref({});
    let oldOrganizationVals = ref({});

    let originalState = ref("0");
    let originalCountry = ref("US");
    let isLoaded = ref(false);
    let msg = ref([]);

    let isValidEmail = computed(() => {
      return EMAIL_REGEX.test(outstateOrganization.value.contactEmail);
    })


    onBeforeMount(() => {
      outstateOrganization.value.countryCode = props.countryCode;
      outstateOrganization.value.stateCode = props.stateCode;
      outstateOrganization.value.organizationName = props.orgName;
      outstateOrganization.value.requestingUserName = props.requestingUserName ? props.requestingUserName : "";

      originalCountry.value = props.countryCode;
      originalState.value = props.stateCode;

      if (!props.editingExisting) {
        // originalCountry.value = authUser.value.country.countryCode;
      }

      isLoaded.value = true;
    })

    const showStateDisplay = computed(() => {
      return outstateOrganization.value.countryCode === 'US';
    })

    const showMazInput = computed(() => {
      return !updatingCountry.value && !showStateDisplay.value;
    })

    const requestingNameRequired = computed(() => {
      return outstateOrganization.value.contactName !== outstateOrganization.value.requestingUserName;
    })

    watch(() => outstateOrganization.value.phoneNumber, (first, second) => {

      if (showStateDisplay.value) {
        let mask;
        const numeric = outstateOrganization.value.phoneNumber.replaceAll(/\D/g, '');
        mask = createPhoneInputMask(numeric);
        outstateOrganization.value.phoneNumber = numericStringMask(outstateOrganization.value.phoneNumber, mask);
      }
    })

    watch(() => outstateOrganization.value.faxNumber, (first, second) => {

      if (showStateDisplay.value) {
        let mask;
        const numeric = outstateOrganization.value.faxNumber.replaceAll(/\D/g, '');
        mask = createPhoneInputMask(numeric);
        outstateOrganization.value.faxNumber = numericStringMask(outstateOrganization.value.faxNumber, mask);
      }

    })

    watch(() => props.countryCode, (first, second) => {
      originalCountry.value = props.countryCode;
    })

    watch(() => props.stateCode, (first, second) => {
      originalState.value = props.stateCode;
    })

    watch(() => props.orgName, (first, second) => {
      outstateOrganization.value.organizationName = props.orgName;
    })

    watch(() => outstateOrganization.value.countryCode, (first, second) => {
      originalCountry.value = outstateOrganization.value.countryCode;
    })


    function validateEmail(event) {
      if (outstateOrganization.value.contactEmail.length === 0) {
        msg.value['email'] = 'Email is required.'
      } else if (!isValidEmail.value) {
        msg.value['email'] = 'Invalid Email Address Format.';
      } else {
        delete msg.value['email'];
      }
    }

    function validStr(event) {
      outstateOrganization.value[event.target.id] = sanitizeNonPasswordInput(event.target.value);
      if (outstateOrganization.value[event.target.id].length === 0) {
        msg.value[event.target.id] = 'required.';
      } else {
        delete msg.value[event.target.id];
      }
    }

    async function changeCountry(event) {
      // outstateOrganization.value.countryCode = event;
      // context.emit("countryCode", outstateOrganization.value.countryCode);
      updatingCountry.value = true;
      await nextTick().then(() => {
        outstateOrganization.value.countryCode = event;
        phoneInputModel.value.countryCode = event;
      }).finally(() => {
        updatingCountry.value = false;
      })

    }

    function changeState(event) {
      outstateOrganization.value.stateCode = event;
      context.emit("idState", outstateOrganization.value.stateCode);
    }

    async function recaptcha() {
      await recaptchaLoaded();
      return await executeRecaptcha('login');
    }


    const canSubmit = computed(() => {
      let valid_name = (outstateOrganization.value.organizationName !== "" &&
          outstateOrganization.value.organizationName !== undefined && outstateOrganization.value.organizationName !== null);
      let valid_email = EMAIL_REGEX.test(outstateOrganization.value.contactEmail);
      let valid_contact_name = (outstateOrganization.value.contactName !== "" && outstateOrganization.value.contactName !== undefined
          && outstateOrganization.value.contactName !== null);
      let valid_requesting_user = (outstateOrganization.value.requestingUserName !== ""
          && outstateOrganization.value.requestingUserName !== undefined && outstateOrganization.value.requestingUserName !== null);

      let valid_phone = (outstateOrganization.value.phoneNumber !== ""
          && outstateOrganization.value.phoneNumber !== undefined && outstateOrganization.value.phoneNumber !== null);

      let state_valid;

      state_valid = showStateDisplay.value ? (outstateOrganization.value.stateCode !== "0"
          && outstateOrganization.value.stateCode !== null && outstateOrganization.value.stateCode !== undefined) : true;


      return valid_name && valid_email && state_valid && valid_contact_name && valid_requesting_user && valid_phone;
    })

    async function submitForm() {
      let request_body = {
        organizationName: outstateOrganization.value.organizationName,
        contactEmail: outstateOrganization.value.contactEmail,
        stateCode: outstateOrganization.value.stateCode,
        countryCode: outstateOrganization.value.countryCode,
        contactName: outstateOrganization.value.contactName,
        phoneNumber: outstateOrganization.value.phoneNumber,
        faxNumber: outstateOrganization.value.faxNumber ? outstateOrganization.value.faxNumber : "",
        address: outstateOrganization.value.address ? outstateOrganization.value.address : "",
        city: outstateOrganization.value.city ? outstateOrganization.value.city : "",
        postalCode: outstateOrganization.value.postalCode ? outstateOrganization.value.postalCode : "",
        requestingUserName: outstateOrganization.value.requestingUserName
      }
      recaptcha().then((token) => {
        submitCreate(request_body, token);
      })
    }

    async function submitCreate(postBody, recaptchaToken) {
      let post_uri = API_URL + "/outNeOrganization/createFromRegistration";
      await axios.post(post_uri, postBody, {
        params: {
          'g-recaptcha-response': recaptchaToken
        }
      }).then(() => {
        context.emit('closeModal', true);
      }).finally(() => {
        Swal.fire({
          title: "Institution Successfully Created",
          text: "Created " + outstateOrganization.value.organizationName + " successfully.",
          icon: 'success'
        }).then(() => {
          context.emit('educationalInstitution', outstateOrganization.value.organizationName);
        })
      })
    }

    return {
      outstateOrganization,
      showStateDisplay,
      originalState,
      oldOrganizationVals,
      isLoaded,
      canSubmit,
      originalCountry,
      msg,
      updatingCountry,
      phoneInputModel,
      showMazInput,
      requestingNameRequired,
      validStr,
      validateEmail,
      changeCountry,
      changeState,
      submitForm
    }
  }
}
</script>

<style scoped>
h4 {
  opacity: 1;
  color: black;
}

span {
  color: red;
}

input {
  --maz-color-info: rgba(59, 130, 246, 0.5);
  border: 1px solid black;
}

.swal2-container {
  z-index: 10000;
}
</style>