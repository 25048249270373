<template>
  <div>
    <label for="educationalInstitution">Select an Organization<span>*</span></label>
    <input
        type="text"
        v-model="educationalInstitution"
        placeholder="Educational Organization"
        list="organizations"
        id="educationalInstitution"
    />
    <datalist id="organizations" v-if="filteredCount > 0">
      <option
          v-for="organization in filteredOrganizations"
          :key="organization.idOrganization"
          :value="organization.organizationName"
          v-on:click="selectExistingOrg(organization.organizationName)"
      >
        {{ organization.organizationName }}
      </option>
    </datalist>
    <div v-if="showButton">
      <h5>Don't see the organization you're looking for?</h5>
      <button type="button" class="button btn-primary" @click="showModal = true">Create the Organization</button>
    </div>
    <Teleport to="body">
      <OutNeInstitutionModal :show="showModal"
                             @close="refreshAfterNewSubmit"
                             :country-code="countryCode"
                             :state-code="stateCode"
                             :default-name="educationalInstitution"
                             @educationalInstitution="updateEducationalOrganization($event)"
      >

      </OutNeInstitutionModal>
    </Teleport>
  </div>
</template>

<script>
import {ref, onBeforeMount, computed, watch} from "vue";
import {API_URL} from "../../../Constants";
import axios from "axios";
import {sanitizeNonPasswordInput} from "@/SanitizeInput";
import OutNeInstitutionModal from "@/components/InstitutionForm/OutNeInstitutionModal";

export default {
  name: "OutNeOrganization",
  components: {
    OutNeInstitutionModal
  },
  props: {
    countryCode: String,
    stateCode: String,
    defaultName: String,
    disableSelect: Boolean
  },
  setup(props, context) {
    const showModal = ref(false);
    const organizations = ref([]);
    const educationalInstitution = ref("");

    onBeforeMount(() => {
      getOrganizations();

      if (props.defaultName) {
        selectExistingOrg(props.defaultName);
      }
    })

    watch(() => props.stateCode, (first, second) => {
      getOrganizations();
    })

    watch(() => props.countryCode, (first, second) => {
      getOrganizations();
    })

    watch(() => props.defaultName, (first, second) => {
     selectExistingOrg(props.defaultName)
    })

    async function getOrganizations() {
      let get_uri = API_URL + "/outNeOrganization/";

      if (props.countryCode !== 'US') {
        get_uri += "getByCountry?_countryCode=" + props.countryCode;
      } else {
        get_uri += "getByState?_stateShort=" + props.stateCode;
      }

      await axios.get(get_uri)
          .then((result) => {
            organizations.value = result.data;
          })
    }

    function updateEducationalOrganization(event) {
      showModal.value = false;
      educationalInstitution.value = sanitizeNonPasswordInput(event);

      context.emit("filteredOrgCount", filteredOrganizations.value.length);
      selectExistingOrg(educationalInstitution.value);
      refreshAfterNewSubmit();
    }

    function selectExistingOrg(value) {
      educationalInstitution.value = value;
      context.emit("educationalInstitution", value);
    }

    const filteredOrganizations = computed(() => {
      return organizations.value.filter((organization) => {
        return organization.organizationName.toLowerCase().includes(educationalInstitution.value.toLowerCase());
      })
    })

    const showButton = computed(() => {
      return filteredOrganizations.value.length <= 0;
    })

    const filteredCount = computed(() => {
      return filteredOrganizations.value.length;
    })

    function refreshAfterNewSubmit() {
      showModal.value = false;
      getOrganizations();
    }

    return {
      showModal,
      showButton,
      filteredOrganizations,
      educationalInstitution,
      filteredCount,
      refreshAfterNewSubmit,
      updateEducationalOrganization,
      selectExistingOrg
    }
  }
}
</script>

<style scoped>
datalist {
  display: block;
  position: relative;
  background-color: #e2e2e2;
  border: 1px solid black;
  border-radius: 5px;
  width: auto;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto
}

datalist option {
  position: relative;
  border-bottom: black 1px;
  background-color: #e2e2e2;
  padding: 4px;
  color: black;
  margin-bottom: 1px;
  border-radius: 2px;
  cursor: pointer;
}

option:hover {
  background-color: #1e90ff;
}
span {
  color: red;
}
</style>