<template>
  <div v-if="show">
    <hr/>
    <Transition name="slide-fade">
      <div>
        <p>To request an ESU level access account please click the button below.</p>
        <p>An ESU account has access to any district that is tied to that ESU.</p>

        <a
            class="button btn btn-primary"
            role="button"
            target="_blank"
            @click="helpButtonClicked"
        >Request ESU Account</a>
      </div>
    </Transition>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {isDistrictAdmin} from "@/RoleCheck";


export default {
  name: "RequestEsuAdminAccount",
  props: {
    stateShort: {
      type: String,
      nullable: true
    },
    idRole: {
      type: Number,
      default: -1,
    },
    selfRegistering: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    function helpButtonClicked() {
      window.open('https://nebraska.jitbit.com/helpdesk/Tickets/New?categoryId=513886', 'pagename', 'resizeable,height=700,width=1000');
      return false;
    }

    const show = computed(() => {
      return isDistrictAdmin(props.idRole) && props.stateShort === "NE" && props.selfRegistering;
    })

    return {
      show,
      helpButtonClicked,
    }
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

p {
  font-style: italic;
}
</style>