<template>
  <div v-if="registrationRunning">
    <Loader :loading-value="`Registering User`"/>
  </div>
  <div class="row" v-else>
    <div class="col-sm">
      <label>First Name<span>*</span></label>
      <input class="form-control" type="text"
             v-model="registrationFormBody.firstName"
             placeholder="First Name (Required)"
             id="firstName"
             @change="changeStringValue($event)" style="height: 41px;"
             tabindex="1"
             required/>
      <label>Last Name<span>*</span></label>
      <input class="form-control" type="text" v-model="registrationFormBody.lastName" id="lastName"
             @change="changeStringValue($event)"
             tabindex="2"
             placeholder="Last Name (Required)" style="height: 41px;" required/>
      <label for="jobHierarchyId">Job Title<span>*</span></label>

      <select class="form-select" v-model="registrationFormBody.jobHierarchyId" id="jobHierarchyId"
              @change="changeNumberValue($event)"
              style="height: 41px;" tabindex="3">
        <option :value="-1" selected>Select a Job Title</option>
        <option v-if="(superUserRegistering && registeringUserPositionId === 1)" :value="1">ESUCC Staff</option>
        <option v-if="(superUserRegistering && registeringUserPositionId === 1)" :value="2">ESU Administrator
        </option>
        <option :value="3" v-if="(registeringUserPositionId <= 2 || selfRegistering) && !isNonPublic">District
          Admin
        </option>
        <option :value="4" v-if="(registeringUserPositionId <= 3 || selfRegistering)">Supervisor</option>
        <option :value="5">Student (Para Course)</option>
        <option :value="6">Student (Supervisor Course)</option>
        <option v-if="(superUserRegistering && registeringUserPositionId === 1) || selfRegistering" :value="7">Nebraska
          Department
          of
          Education (NDE)
        </option>
      </select>
    </div>
    <div class="col-sm">
      <div v-if="isLoaded">
        <CountrySelectComposition
            :idEsu="registrationFormBody.selectedEsu"
            :idDistrict="registrationFormBody.selectedDistrict"
            :idSchool="registrationFormBody.selectedSchool"
            :selfRegistering="!superUserRegistering"
            :showDistrict="showDistrict"
            :showSchool="showSchool"
            :esuDisabled="esuDisabled"
            :districtDisabled="districtDisabled"
            :schoolDisabled="schoolDisabled"
            :countryDisabled="esuDisabled"
            :stateDisabled="esuDisabled"
            :defaultState="'NE'"
            :default-country="defaultCountry"
            :default-state="defaultState"
            :showEsu="true"
            :showState="true"
            @selectedCountry="changeCountry($event)"
            @selectedState="changeState($event)"
            @selectedEsu="changeEsu($event)"
            @selectedDistrict="changeDistrict($event)"
            @selectedSchool="changeSchool($event)"
            :tab-idx="4"
        />
      </div>
      <div v-if="showOutstate && isLoaded">
        <OutNeOrganizationDropdown
            :state-code="registrationFormBody.selectedState"
            :country-code="registrationFormBody.selectedCountry"
            @educationalInstitution="updateOutstateOrg($event)"
            :refresh-organizations="refreshOrganizations"
            :default-organization="registrationFormBody.educationalInstitution"
            :show-not-listed="true"
            :tabindex="5"
        />
        <div v-if="showModal">
          <Teleport to="body">
            <OutNeInstitutionModal
                :show="showModal"
                :state-code="registrationFormBody.selectedState"
                :country-code="registrationFormBody.selectedCountry"
                :requesting-user-name="`${registrationFormBody.firstName} ${registrationFormBody.lastName}`"
                @educationalInstitution="updateOutstateOrg($event)"
                @refreshOrganizationList="toggleRefreshOrganizations($event)"
                @close="showModal = false"
            />
          </Teleport>
        </div>
        <div v-if="registrationFormBody.educationalInstitution === '0'">
          <label>Don't see your organization? Tell us some basic info!</label>
          <button class="button btn-secondary" @click="toggleModal($event)">Create Organization</button>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <label for="email">Email Address<span>*</span></label>
      <input class="form-control" type="email" v-model="registrationFormBody.email" id="email"
             @change="changeStringValue($event)"
             placeholder="Email Address (Required)" style="height: 41px;" tabindex="6">
      <transition name="fade">
                  <span v-if="msg.email">{{ msg.email }}
                  <br/>
                  </span>
      </transition>
      <label for="emailConfirm">Confirm Email<span>*</span></label>
      <input class="form-control" type="email" v-model="registrationFormBody.emailConfirm" id="emailConfirm"
             @change="changeStringValue($event)"
             placeholder="Confirm Email Address (Required)" style="height: 41px;" tabindex="7"/>
      <transition name="fade">
        <span v-if="msg.emailConfirm">{{ msg.emailConfirm }}<br/></span>

      </transition>
      <button @click="registerUser" class="button btn-primary" type="submit" :disabled="!canSubmit">{{
          submitText
        }}
      </button>
    </div>
  </div>
  <div>
    <RequestEsuAdminAccount :id-role="registrationFormBody.jobHierarchyId"
                            :state-short="registrationFormBody.selectedState"
                            :self-registering="selfRegistering"/>
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants.js";

import {computed, onBeforeMount, ref, watch, watchEffect} from "vue";
import {isEmpty} from "@/objectEmpty";
import CountrySelectComposition from "@/components/LocationSelect/CountrySelectComposition";
import OutNeInstitution from "@/components/InstitutionForm/OutNeInstitution";
import Swal from "sweetalert2";
import {EMAIL_REGEX, NAME_REGEX} from "@/ValidConstants";
import {useReCaptcha} from "vue-recaptcha-v3";
import {sanitizeNonPasswordInput} from "@/SanitizeInput";
import OutNeInstitutionComposition from "@/components/InstitutionForm/OutNeInstitutionComposition";
import OutNeInstitutionModal from "@/components/InstitutionForm/OutNeInstitutionModal";
import OutNeOrganization from "@/components/InstitutionForm/OutNeOrganization";
import OutNeOrganizationDropdown from "@/components/InstitutionForm/OutNeOrganizationDropdown";
import Loader from "@/components/Loader";
import RequestEsuAdminAccount from "@/components/RegistrationEdgeCases/RequestEsuAdminAccount.vue";
import {isNde, validateEmailIfNde} from "@/RoleCheck";

export default {
  name: "RegistrationFormComposition",
  components: {
    RequestEsuAdminAccount,
    Loader,
    OutNeOrganizationDropdown,
    OutNeInstitutionModal,
    // eslint-disable-next-line vue/no-unused-components
    OutNeOrganization/*,
    OutNeInstitutionModal, OutNeInstitutionComposition, OutNeInstitution*/, CountrySelectComposition
  },
  props: {
    superUserRegistering: Boolean,
    submitText: String,
    idEsu: Number,
    idDistrict: Number,
    idSchool: Number,
    authToken: String,
    registeringUserPositionId: Number,
    registeringUserId: Number,
    selfRegistering: Boolean,
    defaultCountry: String,
    defaultState: String,
    defaultOutstateOrgName: String
  },
  // emits: ["email", "firstName", "lastName", "selectedCountry", "selectedState", "selectedEsu", "selectedDistrict", "selectedSchool", "inNebraska", "jobHierarchyId", "outstateOrg"],
  setup(props, context) {
    const registeringUser = ref({});
    const isLoaded = ref(false);
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
    const showModal = ref(false);
    const createNewOutstateOrg = ref(false);
    const refreshOrganizations = ref(false);
    const courseType = ref("none");
    const supervisorCourseSelected = ref(false);
    const registrationRunning = ref(false);

    onBeforeMount(() => {
      if (!props.selfRegistering) {
        getRegisteringUser();
      }
      isLoaded.value = true;
    })

    const esuDisabled = computed(() => {
      return !props.selfRegistering && props.registeringUserPositionId > 1;
    })

    const districtDisabled = computed(() => {
      return !props.selfRegistering && props.registeringUserPositionId > 2;
    })

    const schoolDisabled = computed(() => {
      return !props.selfRegistering && props.registeringUserPositionId > 3;
    })


    watch(() => props.registeringUserId, (first, second) => {
      isLoaded.value = false;
      getRegisteringUser();
      isLoaded.value = true;
    })

    watch(() => props.defaultOutstateOrgName, (first, second) => {
      registrationFormBody.value.educationalInstitution = props.defaultOutstateOrgName;
    })

    watch(() => props.defaultState, (first, second) => {
      registrationFormBody.value.selectedState = props.defaultState;
    })

    watch(() => props.defaultCountry, (first, second) => {
      registrationFormBody.value.selectedCountry = props.defaultCountry;
    })


    const getRegisteringUser = () => {
      let get_uri = API_URL + "/user/byId?_idUser=" + props.registeringUserId
      axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + props.authToken
        }
      }).then((result) => {
        registeringUser.value = result.data;

        if (!props.selfRegistering) {
          changeCountry(result.data.countryCode);
          changeState(result.data.stateCode);
          changeState(result.data.stateCode);
          updateOutstateOrg(result.data.outStateOrgName);
        }

        registrationFormBody.value.selectedCountry = result.data.countryCode;
        registrationFormBody.value.selectedEsu = result.data.idEsu !== null ? result.data.idEsu : -1;
        registrationFormBody.value.selectedDistrict = result.data.idDistrict !== null ? result.data.idDistrict : -1;
        registrationFormBody.value.selectedSchool = result.data.idSchool !== null ? result.data.idSchool : -1;
        registrationFormBody.value.educationalInstitution = result.data.outStateOrgName !== null ? result.data.outStateOrgName : "-1";
      }).finally(() => {
        isLoaded.value = true;
      })
    }


    watch(() => props.idEsu, (first, last) => {
      registrationFormBody.value.selectedEsu = props.idEsu;
    })

    let msg = ref({});
    let registrationFormBody = ref({
      email: "",
      emailConfirm: "",
      firstName: "",
      lastName: "",
      selectedState: "NE",
      selectedCountry: "US",
      selectedEsu: -1,
      selectedDistrict: -1,
      selectedSchool: -1,
      city: "",
      educationalInstitution: "-1",
      inNebraska: true,
      jobHierarchyId: -1,
      enrollmentType: "none"
    });

    let isValidEmail = ref();

    const canSubmit = computed(() => {
      return validSubmit();
    });

    const showOutstate = computed(() => {
      return registrationFormBody.value.inNebraska !== true;
    });


    const showSchool = computed(() => {
      if (!registrationFormBody.value.inNebraska) return false;
      return registrationFormBody.value.jobHierarchyId === 4 || registrationFormBody.value.jobHierarchyId === 5 || registrationFormBody.value.jobHierarchyId === 6;
    });

    const showDistrict = computed(() => {
      if (!registrationFormBody.value.inNebraska || registrationFormBody.value.jobHierarchyId === 7) return false;
      return registrationFormBody.value.jobHierarchyId >= 3;
    });

    const esuCleared = computed(() => {
      if (!registrationFormBody.value.inNebraska/* || registrationFormBody.value.jobHierarchyId === 6*/) return false;
      return registrationFormBody.value.jobHierarchyId >= 1;
    });

    /**
     * Form logic required if the user selects non-public district
     * Non public DAs cannot exist and must be tied to a school.
     *
     * Returns - @type {ComputedRef<boolean>}
     * Whether the user selected non-public as their district
     */
    const isNonPublic = computed(() => {
      return registrationFormBody.value.selectedEsu === 0 || registrationFormBody.value.selectedEsu === 23;
    })

    async function checkUserExists(value) {
      let get_uri = API_URL + "/user/checkEmailExists?_email=" + value;
      let exists = false;
      await axios.get(get_uri)
          .then((result) => {
            exists = result.data;
          })
      return exists;
    }

    function changeStringValue(event) {
      // registrationFormBody.value[event.target.id] = sanitizeNonPasswordInput(event.target.value);
      registrationFormBody.value[event.target.id] = sanitizeNonPasswordInput(event.target.value);
      if (event.target.id === "email") {
        validateEmail(registrationFormBody.value[event.target.id]);
      } else if (event.target.id === "emailConfirm") {
        confirmEmail(registrationFormBody.value[event.target.id]);
      }
    }

    function changeNumberValue(event) {
      registrationFormBody.value[event.target.id] = parseInt(event.target.value);

      if (event.target.id === "jobHierarchyId") {
        changeCourseType();
      }
    }

    function changeEsu(event) {
      registrationFormBody.value.selectedEsu = parseInt(event);
      /**
       * Have to hard code logic for Non-Public district selected to remove DA from dropdown, set
       */
      if (isNonPublic.value) {
        if (registrationFormBody.value.jobHierarchyId <= 3 && registrationFormBody.value.jobHierarchyId !== -1) registrationFormBody.value.jobHierarchyId = 4;
      }
      changeDistrict(-1);
    }

    function changeDistrict(event) {
      registrationFormBody.value.selectedDistrict = parseInt(event);
      changeSchool(-1);
    }

    function changeSchool(event) {
      // console.log(event);
      registrationFormBody.value.selectedSchool = parseInt(event);
    }

    function changeState(event) {
      registrationFormBody.value.selectedState = event;
      registrationFormBody.value.inNebraska = event === 'NE';
    }

    function changeCountry(event) {
      registrationFormBody.value.selectedCountry = event;
      if (event === 'US') {
        changeState('NE')
      } else {
        changeState("-1");
      }
    }

    function changeCourseType(registerForSupervisorCourse = false) {
      if (registrationFormBody.value.jobHierarchyId === 5) {
        courseType.value = "paraTraining";
      } else if (registrationFormBody.value.jobHierarchyId === 6) {
        courseType.value = "supervisorTraining";
      } else {
        courseType.value = registerForSupervisorCourse ? "supervisorTraining" : "none";
      }
    }

    function updateOutstateOrg(event) {
      registrationFormBody.value.educationalInstitution = event;
    }

    function updateOutstateOrgCount(event) {
      createNewOutstateOrg.value = event <= 0;
    }

    async function validateEmail(value) {
      isValidEmail.value = EMAIL_REGEX.test(value);
      if (isValidEmail.value) {
        if (await checkUserExists(value)) {

          msg.value["email"] = "That email address is already in use";
        } else if (isNde(registrationFormBody.value.jobHierarchyId)) {
          msg.value["email"] = validateEmailIfNde(value) ? "" : "To register with that title you must have a Nebraska state email account.";
        } else {
          delete msg.value["email"];
        }
      } else {
        msg.value["email"] = "Invalid email address format";
      }
      validSubmit();
    }

    function confirmEmail(value) {
      if (isValidEmail.value) {
        if (value !== registrationFormBody.value.email) {
          msg.value["emailConfirm"] = "Please ensure emails match";
        } else {
          delete msg.value["emailConfirm"];
        }
      }
      validSubmit();
    }

    function validSubmit() {
      let email_cleared = isEmpty(msg.value) && registrationFormBody.value.email !== "";
      let first_name_cleared = registrationFormBody.value.firstName.match(NAME_REGEX);
      let last_name_cleared = registrationFormBody.value.lastName.match(NAME_REGEX);
      let role_cleared = false;

      if (registrationFormBody.value.jobHierarchyId !== -1) {
        if (esuCleared.value) {
          role_cleared = registrationFormBody.value.selectedEsu !== -1;
        }
        if (showDistrict.value) {
          role_cleared = registrationFormBody.value.selectedDistrict !== -1;
        }
        if (showSchool.value) {
          role_cleared = registrationFormBody.value.selectedSchool !== -1;
        }
        if (!registrationFormBody.value.inNebraska) {
          role_cleared = registrationFormBody.value.educationalInstitution.match(NAME_REGEX);
        }
      }
      return email_cleared && role_cleared && first_name_cleared && last_name_cleared;

    }

    async function registerUser() {
      registrationRunning.value = true;
      let post_body = {
        firstName: registrationFormBody.value.firstName,
        lastName: registrationFormBody.value.lastName,
        email: registrationFormBody.value.email,
        countryCode: registrationFormBody.value.selectedCountry,
        stateCode: registrationFormBody.value.selectedState,
        educationalInstitution: registrationFormBody.value.educationalInstitution,
        idEsu: registrationFormBody.value.selectedEsu,
        idDistrict: registrationFormBody.value.selectedDistrict,
        idSchool: registrationFormBody.value.selectedSchool,
        roleHierarchyId: registrationFormBody.value.jobHierarchyId,

        enrollmentType: courseType.value
      }

      if (!props.selfRegistering) {
        await adminRegisterUser(post_body);
      } else {
        recaptcha()
            .then((token) => {
              selfRegisterUser(post_body, token)
            })
      }
    }

    async function recaptcha() {
      await recaptchaLoaded();
      return await executeRecaptcha('login');
    }

    async function selfRegisterUser(postBody, recaptchaToken) {
      let post_uri = API_URL + "/user/v2/register";
      await axios.post(post_uri, postBody, {
        params: {
          'g-recaptcha-response': recaptchaToken
        }
      }).then(() => {
        registrationRunning.value = false;
        // console.log(result);
        Swal.fire({
          title: 'Registration Request Submitted',
          text: 'The registration process requires a Project Para team member to review and approve your account request.\n' +
              'You can expect your request will be reviewed within 1-2 business days. Once authorized, you will receive another email with further instructions on logging into Canvas',
          confirmButtonText: 'Okay',
          icon: 'success'
        }).then(function () {
          location.replace('/');
        })
      })
    }

    async function adminRegisterUser(postBody) {
      let post_uri = API_URL + "/user/dmSetupUser";
      await axios.post(post_uri, postBody, {
        headers: {
          "Authorization": "Bearer " + props.authToken
        }
      }).then((result) => {
        registrationRunning.value = false;
        Swal.fire({
          title: 'Successfully Registered',
          html: `<p>${registrationFormBody.value.firstName} ${registrationFormBody.value.lastName} will have 24 hours to create a password for Project Para. </p><p>Please be sure to contact them within 24 hours.</p>`,
          confirmButtonText: 'Continue',
          icon: 'success'
        }).then(function () {
          location.reload();
        })
      })
    }

    function toggleModal(event) {
      event.preventDefault();
      showModal.value = true;
    }

    function toggleRefreshOrganizations(event) {
      refreshOrganizations.value = !refreshOrganizations.value;
    }

    return {
      registrationFormBody,
      canSubmit,
      showDistrict,
      showSchool,
      msg,
      isLoaded,
      esuDisabled,
      districtDisabled,
      schoolDisabled,
      showOutstate,
      createNewOutstateOrg,
      showModal,
      refreshOrganizations,
      supervisorCourseSelected,
      isNonPublic,
      registrationRunning,
      toggleModal,
      changeStringValue,
      changeNumberValue,
      changeEsu,
      changeDistrict,
      changeSchool,
      registerUser,
      changeState,
      changeCountry,
      updateOutstateOrgCount,
      updateOutstateOrg,
      toggleRefreshOrganizations,
      changeCourseType,
    }

  }
}
</script>

<style scoped>


span {
  color: red;
  text-align: center;
  font-size: 100%;
}

.card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0);
  border-radius: 0;
}

form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}

.btn-secondary {
  color: #000000;
  background-color: #529cde;
  border-color: #529cde;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

</style>