<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h5 class="modal-title">
                Educational Organization
              </h5>
              <button type="button" class="btn-close" data-dismiss="modal" @click="$emit('close')">
              </button>
            </slot>
          </div>
          <slot name="body">
            <div class="modal-body">
              <OutstateOrganizationPublicForm :editing-existing="false"
                                              :submit-text="'Create Organization'"
                                              :orgName="orgName"
                                              :stateCode="stateCode"
                                              :countryCode="countryCode"
                                              :requesting-user-name="requestingUserName"
                                              @educationalInstitution="updateEducationalOrganization($event)"
                                              @closeModal="$emit('close')"
              />
            </div>
          </slot>

          <div class="modal-footer">

            <!--            <slot name="footer">-->
            <!--              <button class="modal-default-button btn-danger btn" @click="$emit('close')">Close</button>-->
            <!--            </slot>-->
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {watch, ref, onBeforeMount} from "vue";
import OutstateOrganizationPublicForm
  from "@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationPublicForm";

export default {
  name: "OutNeInstitutionModal",
  components: {
    OutstateOrganizationPublicForm,
  },
  props: {
    show: Boolean,
    stateCode: String,
    countryCode: String,
    defaultName: String,
    requestingUserName: String
  },
  setup(props, context) {
    const selectedState = ref("");
    const selectedCountry = ref("");
    const orgName = ref("");
    const refreshData = ref(false);
    const orgCount = ref(null);

    onBeforeMount(() => {
      if (props.defaultName) {
        updateEducationalOrganization(props.defaultName);
      }
    })

    watch(() => props.defaultName, (first, second) => {
      orgName.value = props.defaultName;
    })

    watch(() => props.stateCode, (first, second) => {
      selectedState.value = props.stateCode;
    })

    watch(() => props.countryCode, (first, second) => {
      selectedCountry.value = props.countryCode;
    })

    function updateOrgName(event) {
      orgName.value = event;
    }

    function updateEducationalOrganization(event) {
      context.emit("educationalInstitution", event);
      context.emit("close", true);
      context.emit("refreshOrganizationList", event);
    }


    return {
      selectedState,
      selectedCountry,
      orgName,
      refreshData,
      orgCount,
      updateOrgName,
      updateEducationalOrganization
    }
  }
}

</script>

<style scoped>
.modal-mask {
  position: fixed;
  /*z-index: 9998;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.modal-container {
  max-width: 800px;
  width: 80%;
  height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input {
  border: 1px solid black;
}
</style>